// core version + navigation, pagination modules:
import Swiper, { Autoplay } from 'swiper';

// init Swiper:
export function swiper() {
  var swiper = new Swiper('.mySwiper', {
    modules: [Autoplay],
    autoplay: {
      delay: 2500,
      disableOnInteraction: false,
    },
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    loop: true,
    spaceBetween: 0,
  });
}
