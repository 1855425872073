/**
 * スクロールアニメーションを表現する。
 */
export function bagSingle() {
  const single = document.getElementById('bag_single'); //アニメーションさせたい要素

  if (single) {
    const offset = 200; // 200pxくらい見えたタイミング
    appear(single, offset);
  }
}

export function bagDouble() {
  const double = document.getElementById('bag_double'); //アニメーションさせたい要素

  if (double) {
    const offset = 200; // 200pxくらい見えたタイミング
    appear(double, offset);
  }
}

/**
 * スクロール量に応じてクラスの付与/削除を行う
 * @param {object} target
 * @param {number} offset
 */
function appear(target, offset) {
  const scroll = window.scrollY; //スクロール量を取得
  const h = window.innerHeight; //画面の高さを取得

  let pos = target.getBoundingClientRect().top + scroll; //アニメーションさせたい要素の位置を取得
  if (scroll > pos - h + offset) {
    //スクロール量 > アニメーションさせたい要素の位置
    target.classList.add('active');
  } else {
    target.classList.remove('active');
  }
}
