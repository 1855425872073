/**
 * スクロール量に応じてheaderの影を付与/削除する
 */
export function headerShadow() {
  const jsHeader = document.querySelector('.js-header');
  const jsHeaderInner = document.querySelectorAll('.js-headerInner');

  const scrollHeight = window.pageYOffset;
  const hero = document.querySelector('.js-hero');

  if (!hero) {
    return;
  }

  const heroHeight = hero.clientHeight;

  // 画面トップからの距離から画面の高さより小さければ実行する
  if (scrollHeight > heroHeight) {
    jsHeader.classList.add('is-active');
    jsHeaderInner.forEach((element) => {
      element.classList.add('is-active');
    });
  } else {
    jsHeader.classList.remove('is-active');
    jsHeaderInner.forEach((element) => {
      element.classList.remove('is-active');
    });
  }
}
