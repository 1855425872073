/**
 * 画像が時間差で出現するアニメーション
 * どの画像が選ばれるかはランダムで決められる
 */
export function randomMv() {
  const all = document.querySelectorAll('.p-hero_bag');

  const select = [];
  // bag_centerの周囲1マス
  const aroundCenter = [10, 11, 12, 17, 19, 24, 25, 26]; // bag_center周囲1マスのdata-number

  // bag_center周囲1マスから被りなしで3つ抽選する
  for (let i = 1; i < 4; i++) {
    while (true) {
      const a = aroundCenter[Math.floor(Math.random() * aroundCenter.length)];

      if (!select.includes(a)) {
        select.push(a);
        break;
      }
    }
  }

  // 全体から被りなしで7つ抽選する
  for (let i = 1; i < 8; i++) {
    while (true) {
      const r = getRandomInt(1, 35);

      if (!select.includes(r) && r != 18) {
        select.push(r);
        break;
      }
    }
  }

  // 配列に入っている順番を使って番号を振る。0番目補正のため +1
  // 優先3つとランダム7つで合計10つ
  all.forEach((element) => {
    const n = element.dataset.number;

    const m = select.indexOf(Number(n));

    if (m !== -1) {
      element.classList.add(`is-hidden-${m + 1}`);
    }
  });
}

/**
 * 整数の範囲でランダムに数字を返す
 * @param {number} min
 * @param {number} max
 * @returns
 */
function getRandomInt(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min);
}
