import { aos } from './modules/aos';
import { footerAppear } from './modules/footer';
import { toggleHamburger } from './modules/hamburger';
import { headerShadow } from './modules/header';
import { randomMv } from './modules/mv';
import { scrollHint } from './modules/scrollHint';
import { bagSingle, bagDouble } from './modules/svg';
import { swiper } from './modules/swiper';

aos();
footerAppear();
toggleHamburger();
headerShadow();
randomMv();
bagSingle();
bagDouble();
swiper();

window.addEventListener('scroll', function () {
  footerAppear();
  headerShadow();
  bagSingle();
  bagDouble();
});

// 画面幅によって実行する関数を制御する
const onlysp = window.matchMedia('(max-width: 767px)');

/**
 * 指定された画面幅かどうかをチェックするだけ
 * @param {object} target
 * @returns {boolean}
 */
function check(target) {
  return target.matches;
}

// SPの時だけ実行される
const sp = check(onlysp);
if (sp) {
  scrollHint();
}
