/**
 * スクロール量に応じてheaderの影を付与/削除する
 */
export function footerAppear() {
  const jsFooterContent = document.querySelector('.js-footerContent');

  const scrollHeight = window.pageYOffset;
  const hero = document.querySelector('.js-hero');

  if (!hero) {
    return;
  }

  const heroHeight = hero.clientHeight;

  // 画面トップからの距離から画面の高さより小さければ実行する
  if (scrollHeight > heroHeight) {
    jsFooterContent.classList.add('is-active');
  } else {
    jsFooterContent.classList.remove('is-active');
  }
}
